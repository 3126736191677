import React from 'react'
import { Pagination as PaginationType, Language } from 'generated/sdk'
import { SvgPaginationArrowRight } from 'components/svgs/svg-pagination-arrow-right'
import { useLocationContext } from 'components/location-provider/location-provider'
import styled, { css } from 'styled-components'
import { Text, TextWeights, Paragraph } from 'components/text/text'
import { NextLink } from 'components/next-link/next-link'
import { useRouter } from 'next/router'
import { getUrlPathFromQuery } from 'utils/url'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { mediaBreakpointUpLg, mediaBreakpointUpXl } from 'theme/breakpoints'
import { stringify } from 'querystring'
import { transition } from 'theme/utils'

type PaginationProps = PaginationType

const PAGINATION_TEXT = {
	previous: {
		[Language.Pt]: 'Página Anterior',
		[Language.En]: 'Previous Page',
	},
	next: {
		[Language.Pt]: 'Próxima Página',
		[Language.En]: 'Next Page',
	},
}

const PaginationWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (width <= 479px) {
		justify-content: center;
	}
`

const PaginationSection = styled.div<{ isArrow?: boolean }>`
	display: flex;
	align-items: center;

	@media (width <= 479px) {
		${props => props.isArrow && 'display: none;'}
	}
`

const commonPageButtonStyles = css<{ isFirst?: boolean; isLast?: boolean; isCurrent?: boolean; isDisabled?: boolean }>`
	display: flex;
	align-items: center;
	padding: 5px 8px 3px;
	margin: 0 4px;
	${props => props.isFirst && 'margin-left: 0;'}
	${props => props.isLast && 'margin-right: 0;'}
  text-transform: uppercase;
	letter-spacing: 0.5px;
	pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
	user-select: ${props => (props.isDisabled ? 'none' : 'auto')};
	background: ${props => (props.isCurrent ? props.theme.colors.paleGrey : 'transparent')};
	border-radius: 2px;
	opacity: ${props => (props.isDisabled ? 0.2 : 1)};

	${Paragraph} {
		padding-top: 2px;
		margin: 0;
		font-size: 16px;
		line-height: 14px;
		color: ${props => (props.isCurrent ? props.theme.color.secondary : props.theme.color.primary)};

		${mediaBreakpointUpLg} {
			font-size: 15px;
			line-height: 13px;
		}

		${mediaBreakpointUpXl} {
			font-size: 16px;
			line-height: 14px;
		}
	}

	svg {
		margin-bottom: 2px;
		line-height: 16px;

		${mediaBreakpointUpLg} {
			line-height: 15px;
		}

		${mediaBreakpointUpXl} {
			line-height: 16px;
		}

		path {
			fill: ${props => props.theme.color.primary};
			transition: ${transition('fill')};
		}
	}

	&:hover {
		text-decoration: none;

		${Paragraph} {
			color: ${props => props.theme.color.secondary};
		}

		svg path {
			fill: ${props => props.theme.color.secondary};
		}
	}

	&:focus {
		outline: none;
	}
`

const PageButton = styled(NextLink)<{ isFirst?: boolean; isLast?: boolean; isCurrent?: boolean; isDisabled?: boolean }>`
	${commonPageButtonStyles}
`

const PageButtonWrapper = styled.div<{
	isFirst?: boolean
	isLast?: boolean
	isCurrent?: boolean
	isDisabled?: boolean
}>`
	${commonPageButtonStyles}
`

const PrevPageButton = styled(PageButton)`
	padding-left: 0;
`

const NextPageButton = styled(PageButton)`
	padding-right: 0;
`

const PrevPageButtonWrapper = styled(PageButtonWrapper)`
	padding-left: 0;
`

const NextPageButtonWrapper = styled(PageButtonWrapper)`
	padding-right: 0;
`

const PaginationArrow = styled(SvgPaginationArrowRight)`
	${mediaBreakpointUpLg} {
		${props => (props.rotate ? 'margin-right: 8px;' : 'margin-left: 8px;')}
	}
`

const PageSeparator = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	padding: 5px 0 3px;
	margin: 0 4px;
	font-size: 16px;
	line-height: 16px;

	${mediaBreakpointUpLg} {
		font-size: 15px;
		line-height: 15px;
	}

	${mediaBreakpointUpXl} {
		font-size: 16px;
		line-height: 16px;
	}
`

const PaginationComponent = ({ currentPage, prevPage, nextPage, totalPages }: PaginationProps) => {
	const router = useRouter()
	const { language } = useLocationContext()
	const { isMobile } = useMediaQueryContext()

	const TOTAL_PAGE_BUTTONS = 5
	const SEPARATOR_OFFSET = TOTAL_PAGE_BUTTONS - 2
	const LEFT_OFFSET = SEPARATOR_OFFSET
	const RIGHT_OFFSET = Math.min(totalPages - SEPARATOR_OFFSET, SEPARATOR_OFFSET)
	const PAGE_BUTTONS_MIDDLE = TOTAL_PAGE_BUTTONS - 2

	const { slug, ...queryParams } = router.query
	const currentPath = getUrlPathFromQuery(slug as string[])

	const hasSeparators = totalPages > TOTAL_PAGE_BUTTONS
	const hasLeftSeparator = hasSeparators && currentPage > LEFT_OFFSET
	const hasRightSeparator = hasSeparators && currentPage <= totalPages - RIGHT_OFFSET

	let startPage = hasSeparators ? 2 : 1
	if (hasLeftSeparator && hasRightSeparator) {
		startPage = currentPage - 1
	} else if (hasLeftSeparator) {
		startPage = totalPages - PAGE_BUTTONS_MIDDLE
	}
	const PAGE_BUTTONS_NUMBERS = Array(hasSeparators ? PAGE_BUTTONS_MIDDLE : totalPages)
		.fill(0)
		.map((_, i) => startPage + i)

	const getUrlWithPage = (page: number) => {
		const params = stringify({
			...queryParams,
			page,
		})
		return `${currentPath}?${params}`
	}

	const _renderPrevBtn = () => {
		const btnContent = (
			<>
				<PaginationArrow suffix="-previous" rotate={180} customTitle={PAGINATION_TEXT.previous[language]} />
				{!isMobile && <Text weight={TextWeights.bold}>{PAGINATION_TEXT.previous[language]}</Text>}
			</>
		)

		return prevPage ? (
			<PrevPageButton href={prevPage ? getUrlWithPage(prevPage) : '#'} passHref isFirst isDisabled={!prevPage}>
				{btnContent}
			</PrevPageButton>
		) : (
			<PrevPageButtonWrapper isFirst isDisabled={!prevPage}>
				{btnContent}
			</PrevPageButtonWrapper>
		)
	}

	const _renderNextBtn = () => {
		const btnContent = (
			<>
				{!isMobile && <Text weight={TextWeights.bold}>{PAGINATION_TEXT.next[language]}</Text>}
				<PaginationArrow suffix="-next" customTitle={PAGINATION_TEXT.next[language]} />
			</>
		)

		return nextPage ? (
			<NextPageButton isLast isDisabled={!nextPage} href={nextPage ? getUrlWithPage(nextPage) : '#'} passHref>
				{btnContent}
			</NextPageButton>
		) : (
			<NextPageButtonWrapper isLast isDisabled={!nextPage}>
				{btnContent}
			</NextPageButtonWrapper>
		)
	}

	return (
		<PaginationWrapper>
			<PaginationSection isArrow>{_renderPrevBtn()}</PaginationSection>
			<PaginationSection>
				{hasSeparators && (
					<PageButton isFirst isCurrent={currentPage === 1} href={getUrlWithPage(1)} passHref>
						<Text weight={TextWeights.bold}>1</Text>
					</PageButton>
				)}
				{hasLeftSeparator && <PageSeparator>...</PageSeparator>}
				{PAGE_BUTTONS_NUMBERS.map(page => (
					<PageButton isCurrent={currentPage === page} key={page} href={getUrlWithPage(page)} passHref>
						<Text weight={TextWeights.bold}>{page}</Text>
					</PageButton>
				))}
				{hasRightSeparator && <PageSeparator>...</PageSeparator>}
				{hasSeparators && (
					<PageButton isLast isCurrent={currentPage === totalPages} href={getUrlWithPage(totalPages)} passHref>
						<Text weight={TextWeights.bold}>{totalPages}</Text>
					</PageButton>
				)}
			</PaginationSection>
			<PaginationSection isArrow>{_renderNextBtn()}</PaginationSection>
		</PaginationWrapper>
	)
}

export const Pagination = React.memo(PaginationComponent)
