import React from 'react'
import styled from 'styled-components'
import { LazyImage } from 'components/lazy-image/lazy-image'
import { Tag } from 'components/tag/tag'
import { Title } from 'components/title/title'
import { Text, TextFonts } from 'components/text/text'
import { DateText } from 'components/date-text/date-text'
import { NewsCardTypes } from 'components/news-card/news-card'
import { NextLink } from 'components/next-link/next-link'
import { SvgPlaySmall } from 'components/svgs/svg-play-small'
import { boxShadowRgbaWithHex, positionAbsoluteAndStretch, transition } from 'theme/utils'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { useMediaQueryContext } from 'components/media-query-provider/media-query-provider'
import { SvgPhotoGalleryMedium } from 'components/svgs/svg-photo-gallery-medium'
import { SvgPhotoGallerySmall } from 'components/svgs/svg-photo-gallery-small'
import { SvgPlayExtraSmall } from 'components/svgs/svg-play-extra-small'

type Props = {
	id: string | number
	url: string
	title: string
	description: string
	category: {
		href: string
		label: string
	}
	timestamp: string
	thumbnail: {
		src: string
		srcRetina: string
		srcPlaceholder: string
		alt: string
	}
	type?: NewsCardTypes
	hideTag?: boolean
}

const NewsCardWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 22px;

	${mediaBreakpointUpLg} {
		margin-bottom: 32px;
	}
`

const NewsCardImageWrapper = styled.div`
	position: relative;
	flex: 0 0 60px;
	overflow: hidden;
	border-radius: 2px;

	${mediaBreakpointUpLg} {
		flex: 0 0 160px;
		${props => boxShadowRgbaWithHex('0 1px 15px 0', props.theme.colors.black, 0.1)}
	}

	.image-placeholder::after {
		${positionAbsoluteAndStretch}
		pointer-events: none;
		content: ' ';
		background: ${props => props.theme.colors.twilightBlue};
		opacity: 0;
		transition: ${transition('opacity')};
	}

	&:hover .image-placeholder::after {
		opacity: 0.4;
	}
`

const NewsCardIconLink = styled(NextLink)`
	position: absolute;
	top: 3px;
	right: 3px;
	line-height: 0;

	${mediaBreakpointUpLg} {
		top: 6px;
		right: 10px;
	}
`

const NewsCardBody = styled.div`
	padding-left: 8px;

	${mediaBreakpointUpLg} {
		padding-left: 31px;
	}
`

const NewsCardTag = styled.div`
	margin: -1px 0 0;

	${mediaBreakpointUpLg} {
		margin: -1px 0 6px;
	}
`

const NewsCardTitle = styled(Title)<{ $maxLines: number }>`
	margin: 2px 0 0;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0;
	transition: ${transition('opacity')};

	${mediaBreakpointUpLg} {
		font-size: 20px;
		line-height: 24px;
	}
`

const NewsCardTitleLink = styled(NextLink)`
	${NewsCardTitle} {
		transition: ${transition('color')};
	}

	&:hover {
		text-decoration: none;

		${NewsCardTitle} {
			color: ${props => props.theme.text.secondary};
		}
	}
`

const NewsCardDescription = styled(Text)`
	margin: 2px 0;
	font-size: 14px;
	line-height: 26px;
`

const NewsCardDate = styled.div`
	margin-top: 4px;

	${mediaBreakpointUpLg} {
		margin-top: 5px;
	}
`

const NewsCardSimpleComponent = ({
	id,
	type = NewsCardTypes.simple,
	url,
	title,
	description,
	category,
	timestamp,
	thumbnail,
	hideTag = true,
}: Props) => {
	const { isMobile } = useMediaQueryContext()
	const isPhotoGallery = type === NewsCardTypes.photoGallery
	const isVideo = type === NewsCardTypes.video
	return (
		<NewsCardWrapper>
			<NewsCardImageWrapper>
				<NextLink href={url} passHref>
					<LazyImage {...thumbnail} aspectRatio="160/160" />
				</NextLink>
				{isVideo && (
					<NewsCardIconLink href={url} passHref>
						{isMobile ? <SvgPlayExtraSmall suffix={`-${id}`} /> : <SvgPlaySmall suffix={`-${id}`} />}
					</NewsCardIconLink>
				)}
				{isPhotoGallery && (
					<NewsCardIconLink href={url} passHref>
						{isMobile ? <SvgPhotoGallerySmall suffix={`-${id}`} /> : <SvgPhotoGalleryMedium suffix={`-${id}`} />}
					</NewsCardIconLink>
				)}
			</NewsCardImageWrapper>
			<NewsCardBody>
				{!hideTag && (
					<NewsCardTag>
						<Tag href={category.href} transparent>
							{category.label}
						</Tag>
					</NewsCardTag>
				)}
				<NewsCardTitleLink href={url} passHref>
					<NewsCardTitle size={3} $maxLines={3}>
						{title}
					</NewsCardTitle>
				</NewsCardTitleLink>
				{!isMobile && (
					<NewsCardDescription font={TextFonts.secondary} maxLines={2}>
						{description}
					</NewsCardDescription>
				)}
				<NewsCardDate>
					<DateText timestamp={timestamp} />
				</NewsCardDate>
			</NewsCardBody>
		</NewsCardWrapper>
	)
}

export const NewsCardSimple = React.memo(NewsCardSimpleComponent)
